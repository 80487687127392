<template>
  <div class="list cl">
    <div class="list-nav">
      <div class="column-name">协会介绍</div>
      <ul>
        <li
          v-for="(item,index) in list"
          :key="item.id"
          @click="chageColumn(index)"
          :class="[index == $route.params.num ? 'active' : '']"
        >{{ item.title }}</li>
      </ul>
    </div>
    <div class="list-details list-con">
      <div class="cl" style="border-bottom: 1px solid #033476;padding-bottom: 12px">
        <div class="column-title">{{ list[$route.params.num].title }}</div>
        <div class="bread">
          <span>首页</span>&nbsp;>&nbsp;
          <span>协会介绍</span>&nbsp;>&nbsp;
          <span>{{ list[$route.params.num].title }}</span>
        </div>
      </div>
      <!--<div class="details-titile">{{ details.lanmubiaoti }}</div>-->
      <div class="details-con" v-html="list[$route.params.num].content" style="margin-top: 50px"></div>
    </div>
  </div>
</template>

<script>
import { present } from "../api/api";

export default {
  name: "list-details",
  data() {
    return {
      list: [],
      currentID: "",
      currentName: "",
      details: ""
    };
  },
  methods: {
    init() {
      present().then(res => {
        this.list = res.result.data;
      });
    },
    chageColumn(index) {
      this.$router.push({
        name: "listDetails",
        params: { num: index }
      });
    }
  },
  mounted() {
    this.init();
  },
  watch: {
    $route() {}
  }
};
</script>

<style scoped lang="less">
@import "../assets/style/list";
</style>
